<template>
  <div class="about">
    <p>Drag the p element back and forth between the two rectangles:</p>
    <div class="droptarget" v-on:drop="drop" v-on:dragover="allowDrop">
      <p
        v-on:dragstart="dragStart"
        v-on:drag="dragging"
        v-on:dragend="dragEnd"
        v-on:drop="drop"
        v-on:dragover="allowDrop"
        v-on:touchstart="touchStart"
        draggable="true"
        id="dragtarget"
      >
        Drag me!
      </p>
    </div>

    <div
      class="droptarget"
      v-on:drop="drop"
      v-on:dragover="allowDrop"
      v-on:dragend="dragEnd"
    ></div>
  </div>
</template>

<script>
export default {
  name: "About",
  methods: {
    dragStart(event) {
      event.dataTransfer.setData("Text", event.target.id);
      // document.getElementById("dragtarget").style.display = "none";
      setTimeout(() => {
        // event.target.classList.add("block-hide");
      }, 0);
    },
    dragging(event) {
      event.target.style.color = "transparent";
      // event.target.classList.remove("block-hide");
      // // // event.target.classList.add("block-hide");
    },
    allowDrop(event) {
      // event.target.style.color = "white";
      event.preventDefault();
      // event.target.classList.remove("block-hide");
    },
    drop(event) {
      event.preventDefault();
      var data = event.dataTransfer.getData("Text");
      event.target.appendChild(document.getElementById(data));
      // event.target.classList.remove("block-hide");
      // event.target.style.color = "white";
    },
    dragEnd(event) {
      // event.target.classList.remove("block-hide");
      event.target.style.color = "white";
    },
    touchStart() {
      // alert(1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.about {
  padding-left: 20rem;
  padding-top: 20rem;
  color: $color-white;
  font-size: 2rem;
  line-height: 1;
}

.droptarget {
  float: left;
  width: 100px;
  height: 35px;
  margin: 15px;
  padding: 10px;
  border: 1px solid #aaaaaa;
}

.block-hide {
  opacity: 0;
}

#dragtarget {
  // width: 200px;
  // height: 10// 0px;
  // background-color: green;
}
</style>
